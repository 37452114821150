import React, {useContext, useState} from "react"
import checkoutStyles from '../styles/pages/checkout.module.scss'
import Layout from "../components/common/layout"
import OrderSummary from "../components/checkout/orderSummary"
import Form from "../components/checkout/form"
import Payment from "../components/checkout/payment"
import Ricevuta from "../components/checkout/ricevuta"
import {StoreContext} from "../context/storeContext"
import {getData} from "../utilities/getData"
import Promozioni from "../components/home/promozioni"
import { graphql } from 'gatsby'

const Checkout = ({data, location}) => {
  const promozioni = data.allStrapiPromo.edges.map(promo => ({codice: promo.node.codice, sconto: promo.node.sconto, inizio: promo.node.inizio, fine: promo.node.fine, trattamenti: promo.node.trattamentos})) 
  const { isPromo, promoCodes, setPromoCodes, setIsPromo, committedCart, committedValues, cartCount, getCartTotal, removeItemFromCommittedCart, clearCommittedCart, clearCommittedValues } = useContext(StoreContext)
  let data_ = getData(promozioni, isPromo, promoCodes, committedCart, committedValues, cartCount, getCartTotal, removeItemFromCommittedCart, location)
  const [isBtnVisible, setIsBtnVisible] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const [order, setOrder] = useState({})

  return ( 
    <Layout
      pageMeta={{
        title: "Checkout",
        keywords: ["massoterapia", "savona", "riflessologia plantare"],
        description:
          "checkout shop trattamenti di massoterapia e riflessologia plantare savona",
        link: "/checkout",
      }}
      location={location}
    >
         {/* <Promozioni /> */}
        {(Object.keys(order).length === 0 && order.constructor === Object) && <h1 className="page-title">Checkout</h1>}
        {!(Object.keys(order).length === 0 && order.constructor === Object) && <h1 className="page-title">grazie per il tuo ordine!</h1>}
        {(Object.keys(order).length === 0 && order.constructor === Object) && <div className={checkoutStyles.layout}>
          <Form setIsBtnVisible={setIsBtnVisible} cssClass={checkoutStyles.form}/>  
          <OrderSummary promozioni={promozioni} promoCodes={promoCodes} setPromoCodes={setPromoCodes} isBtnVisible={isBtnVisible} setIsVisible={setIsVisible} setIsPromo={setIsPromo} isPromo={isPromo} data={data_}  cssClass={checkoutStyles.summary} /> 
          <Payment promoCodes={promoCodes} isPromo={isPromo} setOrder={setOrder} isVisible={isVisible} data={data_}  clearCommittedCart={clearCommittedCart} clearCommittedValues={clearCommittedValues} cssClass={checkoutStyles.payment} />
        </div>}
        {!(Object.keys(order).length === 0 && order.constructor === Object) && <div className={checkoutStyles.layout}>
          <Ricevuta data={order} cssClass={checkoutStyles.summary} /> 
        </div>}
    </Layout>
 
  )
}

export default Checkout

 export const promoQuery = graphql`
  query PromoPageQuery {
    allStrapiPromo {
      edges {
        node {
          sconto
          codice
          inizio
          fine
          trattamentos {
            nome
            prezzo
          }
        }
      }
    }
  }
`
