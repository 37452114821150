import {isDateEarlier, formatDate} from "./dateUtils"

export const getData = (promozioni, isPromo, promoCodes, committedCart, committedValues, cartCount, getCartTotal, removeItemFromCommittedCart, location='') => {   
    
    //promo-avvento
   /*  const scontObj = promozioni.filter(promo => { return promo.codice === promoCode.toUpperCase()})     
    const sconto =  isPromo && scontObj[0] ? scontObj[0]['sconto'] : 0
    let today_day = new Date().toLocaleDateString('it-IT', { year: 'numeric', month: '2-digit', day: '2-digit' }).slice(0,2)
    today_day = parseInt(today_day).toString()
    const today_month = new Date().toLocaleDateString('it-IT', { year: 'numeric', month: '2-digit', day: '2-digit' }).slice(3,5)   
    const daily_promo_obj = (today_month === '12') && isPromo ? promozioni.filter(promo => promo.data.slice(0,2).trim() === today_day) : {}
  */

  const scontObj = promozioni.filter(promoObj => promoCodes.includes(promoObj.codice.toUpperCase()))    
  const codici =  isPromo && scontObj ? scontObj.map(obj => obj['codice']) : ''
 
  const today = new Date().toLocaleDateString('it-IT', { year: 'numeric', month: '2-digit', day: '2-digit' })
  const dailyPromoObj = isPromo ? promozioni.filter(promo =>  (isDateEarlier(formatDate(promo.inizio), today) && isDateEarlier(today, formatDate(promo.fine)) && (promo.codice.trim()))) : []
  const activePromos = dailyPromoObj && dailyPromoObj.map(({fine, inizio, sconto, ...obj}) => {
    const trattamentiUnderPromoList = obj['trattamenti'].map(el => el.nome)
    return {...obj, 
    'trattamentiInCartUnderPromo': committedCart && committedCart.filter(trattamento => trattamentiUnderPromoList && trattamentiUnderPromoList.includes(trattamento.nome.trim()))
  }})



  const areThereActivePromos = activePromos.length !== 0 

  const trattamenti = committedCart.map(item => {
  // da ripristinare dopo avvento //const sconto =  isPromo && scontObj && item.promo ? scontObj.filter(obj => obj.codice === item.promo.codice).map(obj => obj['sconto'])[0] : 0
  const sconto =  isPromo && scontObj && item.promo ? scontObj.filter(obj => formatDate(obj.inizio) === today).map(obj => obj['sconto'])[0] : 0    // da rimuovere dopo avvento

    const trattamentiUnderPromoNameList = activePromos.filter(obj => codici.includes(obj.codice)) && item.promo ? activePromos.filter(obj => codici.includes(obj.codice) && obj.codice === item.promo.codice) : []
    const trattamentiInCartUnderPromoNameList = trattamentiUnderPromoNameList[0] && (trattamentiUnderPromoNameList[0].trattamentiInCartUnderPromo.map(obj => obj.nome))
    const prezzoScontato = (sconto > 1) ? (item.prezzo - sconto) : item.prezzo*(1-sconto)
    
    return ((areThereActivePromos && trattamentiUnderPromoNameList[0] && (trattamentiInCartUnderPromoNameList.includes(item.nome)) ? 
    {id: item.strapiId, nome: item.nome, quantity: item.quantity, prezzo: prezzoScontato, foto: item.foto,
      promoAppend: promoCodes.includes(item.promo.codice) ? item.promo.codice : '' , promo: item.promo} :
    {id: item.strapiId, nome: item.nome, quantity: item.quantity, prezzo: item.prezzo, foto: item.foto, promo: item.promo}))})
   

/*     const trattamenti = committedCart.map(item => (daily_promo_obj[0] && (item.nome === daily_promo_obj[0]['trattamento']) ? {id: item.strapiId, nome: item.nome, quantity: item.quantity, prezzo: (item.prezzo - sconto), foto: item.foto} :
    {id: item.strapiId, nome: item.nome, quantity: item.quantity, prezzo: item.prezzo, foto: item.foto}))  */

    const cartTotalFull = getCartTotal(committedCart)
    const cartTotalPromo = (dailyPromoObj === {}) ? cartTotalFull : getCartTotal(trattamenti)
  
    const quantita_totale_trattamenti = cartCount
    const totale_da_pagare = cartTotalPromo

    const products = committedCart.map(item => 
      ({
      name: item.nome,
      unit_amount: {
        currency_code: "EUR",
        value: (item.prezzo).toFixed(2),
      },
      quantity: item.quantity.toString(),
    }))

  if (committedValues.provincia && Object.keys(committedValues).length > 1 ) {
    const data = {
        trattamenti,
        prodotti: products,
        contatti_cliente: {
          nome: committedValues.nome,
          cognome: committedValues.cognome,
          email: committedValues.email,
          telefono: committedValues.telefono,
          codice_fiscale: committedValues.codice_fiscale.toUpperCase()
        },
        indirizzo_fatturazione: {
          indirizzo: committedValues.indirizzo,
          comune: committedValues.comune,
          cap: committedValues.cap,
          provincia: committedValues.provincia.slice(-2),
        },
        totale_da_pagare,
        quantita_totale_trattamenti,
        breakdown: cartTotalFull.toFixed(2),
        discount: (cartTotalFull - cartTotalPromo).toFixed(2),
        email_buono_regalo: committedValues.email_buono_regalo
      }
      return data
    }
      
  return {
      trattamenti: [],
      prodotti: [],
      contatti_cliente: {
        nome: '',
        cognome: '',
        email: '',
        telefono: '',
        codice_fiscale: ''
      },
      indirizzo_fatturazione: {
        indirizzo:  '',
        comune:  '',
        cap:  '',
        provincia:  '',
      },
      totale_da_pagare: '',
      quantita_totale_trattamenti: '',
      breakdown: '',
      discount: '',
      email_buono_regalo: ''
  }
}
